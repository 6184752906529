import React from 'react';
import { Helmet } from 'react-helmet';
import AboutUs from '../components/AboutUs';
import '../styles/321global.css';
import '../styles/launchpad.css';
import '../styles/nav.css';

const AboutPage = () => {
    return (
        <div id="about">
            <Helmet>
                <title>Insurance321 About Us</title>
                <meta name="description" content="About Us"></meta>
            </Helmet>
            <AboutUs />
        </div>
    )

}

export default AboutPage;