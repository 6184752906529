import React from 'react';
import Benefits from './Benefits';
import Footer from './Footer';
import Service from './Service';
import Technology from './Technology';
import Session321 from './Session321';
import logo from '../img/logo-word-mark.svg';
import RocketPhone from '../img/rocket_to_phone/rocket-to-phone-bluebg.gif';
import RocketPhone2 from '../img/rocket_to_phone/rocket-to-phone.gif';
import '../styles/321global.css';
import '../styles/nav.css';
import '../styles/about-us.css';
import '../styles/contact-us.css';
import  '../styles/circles.css';
import { Link } from 'gatsby'

export default function AboutUs (props) {

    return (
        <div>
            <div id="about_hero">
                <Session321 />
                    <div class="hero-top">
                        <div className="hero-top-left">
                            <Link to='/'><div id="logo"><img src={logo} alt="menu icon" height="40px" width="180px"/></div></Link>
                        </div>
                        <div className="hero-top-right">
                            <a href="tel:+1-234-230-6321">
                                <div id="call_div">
                                    <div id="phone_number"><h5>Call <span className="bold">(234) 230-6321</span> for a free quote</h5></div>
                                    <div id="call_icon"><img src={RocketPhone} alt="rocketphone" /></div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="mobile-hero-top">
                        <a href="tel:+1-234-230-6321">
                            <div id="call_div">
                                <div id="phone_number"><span className="bold">(234) 230-6321</span><img src={RocketPhone} alt="rocketphone" /></div>
                                {/* <div id="call_icon"><img src={RocketPhone2} alt="rocketphone" /></div> */}
                            </div>
                        </a>
                    </div>


                    <div className="hero-bottom">
                        <div className="hero-text">
                            <div id="hero_headline"><h1>The Company</h1></div>
                            <div id="hero_subhead">
                                <h3>
                                At Insurance321, we combine industry-leading technology with our understanding of the insurance marketplace to allow 
                                businesses of all sizes in nearly any industry to find the best policy in the shortest amount of time.
                                </h3>
                            </div>
                        </div>
                    </div>
            </div>
            <Technology />
            <Benefits />
            <Service />
            <Footer />
        </div>
    )
}
