import React from 'react';
import '../styles/benefits.css';

export default function Benefits() {
    return (
        <div id="benefits">
            <h2 className="benefits-header">The Benefits</h2>
            <div className="separator" />
            <div className="benefits-grid">
                <div className="benefits-box">
                    <div className="icon-box signal" />
                    <div className="benefits-line">Quickly and easily find your best insurance options</div>
                </div>
                <div className="benefits-box">
                    <div className="icon-box phone" />
                    <div className="benefits-line">Get live answers to your questions from real people</div>
                </div>
                <div className="benefits-box">
                    <div className="icon-box shield" />
                    <div className="benefits-line">Stay protected throughout our secure match process</div>
                </div>
                <div className="benefits-box">
                    <div className="icon-box battery" />
                    <div className="benefits-line">Save room in your budget without cutting corners in your coverage</div>
                </div>
            </div>
        </div>
    )
}