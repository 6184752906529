import React from 'react';
import launchpadgraphic from '../img/launchpad-graphic-v2.png'
import '../styles/technology.css';

export default function Technology() {
    return (
        <div id="differentiator" className="technology-block">
            <h2 className="technology-header">The Technology</h2>
            <div id="launchpad_graphic"><img src={launchpadgraphic} alt="insurance321 launchpad quote system"/></div>
            <div className="diff-copy">
                <h3 classname="technology-subheader">LaunchPad</h3>
                <p>LaunchPad was designed to save you time, effort, and money while still ensuring you get the right coverage.</p>
                <p>You may not know it, but insurance providers have different preferences and specializations when it comes to the businesses they insure. LaunchPad uses the details of your business — like location, size, and industry — to search the largest commercial insurance network available anywhere and match you with the best fit in a provider based on how well they can address your needs.</p>
            </div>
        </div>
    )
}